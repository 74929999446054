import React, {useState, useRef, useEffect} from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { CheckIcon, ArrowLongRightIcon, ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid'
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from 'moment';
import ReCAPTCHA from "react-google-recaptcha";

const callback = function(entries) {
  entries.forEach(entry => {

    // Is the element in the viewport ?
    if (entry.isIntersecting) {

      // Add the fadeIn class:
      entry.target.classList.add("motion-safe:animate-fadeIn");
    } else {

      // Otherwise remove the fadein class
      
    }
  });
};

const IndexPage = () => {

  const examplesRef = useRef();

   const [captcha, setCaptcha] = useState('');

  const [name, setName] = useState('');

  const [businessName, setBusinessName] = useState('');

  const [showMessage, setShowMessage] = useState(false);

  const phoneRef = useRef();

  const [email, setEmail] = useState('');

  const [prevDisabled, setPrevDisabled] = useState(true);

  const [selected, setSelected] = useState('');

  const [selectedTime, setSelectedTime] = useState('');

  const [dates, setDates] = useState([]);

  const [times, setTimes] = useState([]);

  const [error, setError] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const today = new Date();

  const [delta, setDelta] = useState(500);

  
  

  const next = () => {
    
    setPrevDisabled(false);
    

    examplesRef.current.scrollLeft = examplesRef.current.scrollLeft+delta;
  }

  const prev = () => {
    if (examplesRef.current.scrollLeft < 300) {
      return 0;
    }

   // alert(examplesRef.current.scrollLeft-delta);

    if (examplesRef.current.scrollLeft-delta < 400) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }
    examplesRef.current.scrollLeft = examplesRef.current.scrollLeft-delta;
  }

  const toggleDate = (date) => {

    if (selected == date) {
      //alert('remove '+date);
      setSelected('');
    } else {
      setSelected(date);
    }
  }

  const toggleTime = (time) => {
    if (selectedTime == time) {
      //alert('remove '+date);
      setSelectedTime('');
    } else {
     // alert('add '+d);
      setSelectedTime(time);
    }
  }

  useEffect(() => {

    const targets = document.querySelectorAll(".on-scroll");

      // Set up a new observer
    const observer = new IntersectionObserver(callback);

    
    targets.forEach(function(target) {
      // Hide the element
      target.classList.add("opacity-0");

      // Add the element to the watcher
      observer.observe(target);
    });

    const _dates = [];
    const _times = [];

    let _delta = window.innerWidth/2;
    if (window.innerWidth < 600) {
      _delta = window.innerWidth;
    }

    setDelta(_delta);
    for(var d = 1; d < 8; d++) {
         
        var dt = moment().add(d, 'days');
        _dates.push(dt);
      
    }

    for(var t = 9; t < 20; t++) {
         
        var time = moment().set('hours', t).set('minutes', 0);
        _times.push(time);
      
    }

    setDates(_dates);
    setTimes(_times);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('name')) {
      setName(urlParams.get('name'));
    }

    if (urlParams.get('email')) {
      setEmail(urlParams.get('email'));
    }

  },[]);



  

  const submitForm = async(event) => {
    setShowMessage(false);
    setError(false);

    event.preventDefault();

    const phone = phoneRef.current.value;

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError('Please enter a valid email');
      return false;
    } else if (name.length == 0 || email.length == 0 || phone.length == 0 || businessName.length == 0) {
      setError('Please complete the required (*) fields');
      return false;
    } else if (captcha == '') {
      setError('Please confirm that you\'re not a robot!');
      return false;
    }

    setDisabled(true);

    const myForm = event.target;
    const formData = new FormData(myForm);



    formData.append('g-recaptcha-response', captcha);
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    }).then(() => {
      setShowMessage(true);
    }).catch((error) => {
      setDisabled(false);
    });
  }

  const [menu, setMenu] = useState(false);

  return <main className="bg-[#eeeadc] min-h-screen border-t-8 border-accent">
    <nav className="">
      <div className="container relative mx-auto px-4 flex items-center justify-between py-4">
        <Link to="/" className="font-bold  font-apparat  text-xl tracking-wider text-black rounded px-3 py-2">Dave Luke &middot; NYC</Link>
        
        <label for="check" className={` burger flex scale-50 md:hidden`}>
            <input type="checkbox" id="check" checked={menu} onChange={(e) => setMenu(e.target.checked)}/> 
            <span></span>
            <span></span>
            <span></span>
          </label>

      <ul className={` ${menu?'block z-50 absolute top-20 bg-[#eeeadc] pb-8 left-0 text-3xl space-y-4 px-7 shadow-xl w-full':'hidden'} sm:flex items-center sm:space-x-10 text-xl `}>
          <li><Link onClick={() => setMenu(false)} to="#about" className="text-black">About</Link></li>
          <li><Link onClick={() => setMenu(false)} to="#examples" className="text-black">Examples</Link></li>
          <li><Link onClick={() => setMenu(false)} to="#pricing" className="text-black">Pricing</Link></li>
          <li><Link onClick={() => setMenu(false)} to="#contact" className="text-black sm:text-white sm:bg-black sm:px-6 sm:py-3 sm:rounded">Get Started</Link></li>
        </ul>
      </div>
    </nav>
    <div className="container mx-auto sm:grid sm:grid-cols-2 lg:min-h-[760px] h-[90vh] gap-8 px-4">
      <div className="sm:flex items-center sm:hidden">
          <img src="https://res.cloudinary.com/meshed-nyc/image/upload/v1721763445/burg_yr45qy.png" className="on-scroll"/>
        </div>
      <div className="flex items-center col-span-1">
        <div className="">
        <h1 className="font-bold text-left text-4xl md:text-6xl mb-3 font-apparat">Hi, I make websites for local resturants in NYC!</h1>
        <p className="text-lg font-sans sm:text-2xl mb-6 sm:mb-10">Affordable custom designed websites for restaurants in NYC.</p>
        <div className="flex justify-start items-center space-x-2 sm:space-x-4">
        <Link to="#contact" className="btn-primary block font-apparat w-full sm:w-auto sm:inline-block">Book a Call</Link>
        </div>
        </div>

      </div>
      <div className="sm:flex items-center hidden">
          <img src="https://res.cloudinary.com/meshed-nyc/image/upload/v1721763445/burg_yr45qy.png" className="on-scroll block mx-auto max-w-lg"/>
        </div>
      
      
    </div>
    <div className="">
    <div className="container mx-auto py-20 px-4">
      <h3 className="md:text-center text-5xl font-apparat font-bold">Why Do Restaurants Need a Website?</h3>
      <hr className="md:mx-auto border-b-8 my-10 w-24 border-accent border-t-0"/>
      <div className="grid md:grid-cols-3 gap-8">
        <div className="on-scroll">
          <h3 className="text-3xl font-apparat  font-bold mb-2">Make a Great First Impression</h3>
          <p className="text-xl">Having a professionally designed website is important to make a positive first impression. An attractive design can also create a cohesive brand identity.</p>
        </div>
        <div className="on-scroll">
          <h3 className="text-3xl font-apparat  font-bold mb-2">Information Accessiblilty</h3>
          <p className="text-xl">Show business hours, your restaurant's address, contact information, and social media links</p>
        </div>
        <div className="on-scroll">
          <h3 className="text-3xl font-apparat  font-bold mb-2">Online Ordering + Reservations</h3>
          <p className="text-xl">Give users easy access to online ordering via Uber Eats, Doordash, etc.. and reservations via OpenTable and Resy.</p>
        </div>
      </div>
    </div>
    </div>
    <div className="py-20" id="about">
    <div className="container mx-auto px-4 ">
      <h3 className="text-center text-5xl font-apparat font-bold">Why hire me?</h3>
      <hr className="mx-auto border-b-8 my-8 w-24 border-accent border-t-0"/>
      <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-8">
        <div>
          <img src="https://res.cloudinary.com/meshed-nyc/image/upload/v1720482496/couch_sx0zch.png" className="hidden"/>
        </div>
        
        <div className="text-left space-y-4 mt-8">
          <p className="text-xl">Hi, I'm Dave. I'm a NYC based web designer, and I'd love to design your website!</p>
          <p className="text-xl">While a number of companies offer costly website packages for restaurants, they are often made with prepackaged templates. I can offer you an original design, as well custom technical solutions based on your business' unique needs.</p>
          <p className="text-xl">My rates and hosting fees are both very affordable. And I can act as your personal liason to support your website.</p>
          
        </div>
        

        </div>
    </div>
    </div>
    <div className="py-20" id="examples">
      <div className="container mx-auto px-4">
         <h3 className="text-center text-4xl font-apparat font-bold">Some of my work</h3>
      <hr className="mx-auto border-b-8 my-8 w-24 border-accent border-t-0"/>
        
      </div>
      <div className="sm:overflow-x-auto overflow-y-visible no-scroll snap-x" ref={examplesRef} style={{scrollBehavior:'smooth'}}>
          <div className="sm:flex items-center sm:space-x-8 pb-10 px-4 sm:px-0 space-y-4 sm:space-y-0">
            <div className="w-full hidden md:block max-w-[20vw] shrink-0"/>
            <img className="shrink-0 w-full max-w-4xl shadow-lg snap-center" src="https://res.cloudinary.com/meshed-nyc/w_1000,c_fill,q_auto/Screenshot_2024-07-23_at_11-11-32_Spicy_Lanka_-_Authentic_Sri_Lankan_Food_in_Queens_NY_vxlzhr.jpg"/>
            <img className="shrink-0 w-full max-w-4xl shadow-lg snap-center" src="https://res.cloudinary.com/meshed-nyc/w_1000,c_fill,q_auto/Screenshot_2024-07-23_at_11-12-57_Queens_Together_s_Eats_in_Queens_Restaurant_Month_-_March_2024_jnqmbq.jpg"/>
            <img className="shrink-0 w-full max-w-4xl shadow-lg snap-center" src="https://res.cloudinary.com/meshed-nyc/w_1000,c_fill,q_auto/Screenshot_2024-07-24_at_16-36-34_The_Unofficial_US_Open_Neighborhood_Dining_Guide_Queens_Together_oxefyf.png"/>
            <div className="w-full max-w-4xl shrink-0">&nbsp;</div>
          </div>
        </div>
        <div className="hidden sm:flex items-center justify-center space-x-10">
        <button className="disabled:opacity-30" onClick={() => prev()}><ArrowLeftIcon className="w-8 h-8"/></button>
        <button className="" onClick={() => next()}><ArrowRightIcon className="w-8 h-8"/></button>
        </div>
    </div>
    <div className="py-20" id="pricing">
      <div className="container mx-auto px-4">
         <h3 className="md:text-center text-4xl uppercase tracking-widest font-bold">Clear + Affordable Pricing</h3>
      <hr className="md:mx-auto border-b-8 my-8 w-24 border-accent border-t-0"/>
        <div className="grid md:grid-cols-2 max-w-4xl mx-auto gap-8">
          <div className="border-2 rounded-xl px-8 py-8 border-secondary relative on-scroll">
            <span className="bg-lime-500 text-white px-4 py-2 rounded-xl text-2xl font-bold absolute -right-2 sm:-right-5 -top-5 shadow-xl">$2,000</span>
            <h3 className="text-3xl font-bold uppercase tracking-widest">Custom Website</h3>
            <hr className=" border-b-8 my-4 w-8 border-accent border-t-0"/>
            <ul className="text-2xl space-y-2">
              <li className="flex items-start space-x-3"><CheckIcon className="mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Original custom design</span></li>
              <li className="flex items-start space-x-3"><CheckIcon className="mt-1 w-6 h-6 text-lime-600"/><div><span className="font-bold">Core Pages</span>
                <ul className="block text-lg">
                  <li className="flex items-center space-x-3"><span>Homepage</span></li>
                  <li className="flex items-center space-x-3"><span>About Page</span></li>
                  <li className="flex items-center space-x-3"><span>Menu</span></li>
                  <li className="flex items-center space-x-3"><span>Photo Gallery</span></li>
                  <li className="flex items-center space-x-3"><span>Online Ordering</span></li>
                  <li className="flex items-center space-x-3"><span>Reservations</span></li>
                  <li className="flex items-center space-x-3"><span>Contact Page</span></li>
                </ul>
              </div></li>
              <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Mailing List Sign up<span className="block text-xl font-normal"></span></span></li>
              <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Highlight Positive Press<span className="block text-xl font-normal"></span></span></li>
              <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">SEO<span className="block text-xl font-normal">Show in Google search results</span></span></li>
              <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Responsive Design<span className="block text-xl font-normal">Website adapts to different devices and screen sizes</span></span></li>
              <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Free Hosting for 6 months<span className="block text-xl font-normal">$12 / month afterwards</span></span></li>
            </ul>
          </div>
          <div className="space-y-8">
            <div className="border-2 rounded-xl px-8 py-8 border-secondary relative on-scroll">
              <span className="bg-lime-500 text-white px-4 py-2 rounded-xl text-2xl font-bold absolute -right-2 sm:-right-5 -top-5 shadow-xl">+$300</span>
              
              <h3 className="text-3xl font-bold uppercase tracking-widest">Additional Pages</h3>
              <strong className="text-secondary uppercase text-xl tracking-widest block">Optional</strong>
              <hr className=" border-b-8 my-4 w-8 border-accent border-t-0"/>
              <ul className="text-2xl space-y-2">
                <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Additional Locations<span className="block text-xl font-normal">List up to 5 additional locations</span></span></li>
                <li className="flex items-start space-x-3"><CheckIcon className="shrink-0 mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Catering Page</span></li>
              </ul>
            </div>
            <div className="border-2 rounded-xl px-8 py-8 border-secondary relative on-scroll">
              <span className="bg-lime-500 text-white px-4 py-2 rounded-xl text-2xl font-bold absolute -right-2 sm:-right-5 -top-5 shadow-xl">$99 / month</span>
              <h3 className="text-3xl font-bold uppercase tracking-widest">Managed Updates</h3>
              <strong className="text-secondary uppercase text-xl tracking-widest block">Optional</strong>
              <hr className=" border-b-8 my-4 w-8 border-accent border-t-0"/>
              <ul className="text-2xl space-y-2">
                <li className="flex items-start space-x-3"><CheckIcon className="mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Includes hosting fee<span className="block text-xl font-normal">Managed updates include the $20 monthly hosting fee</span></span></li>
                <li className="flex items-start space-x-3"><CheckIcon className="mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Content Updates<span className="block text-xl font-normal">I will update copy and information on your website for you</span></span></li>
                <li className="flex items-start space-x-3"><CheckIcon className="mt-1 w-6 h-6 text-lime-600"/><span className="font-bold">Premium Support<span className="block text-xl font-normal">Updates will be made within 24 hours Monday through Friday</span></span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="py-20" id="contact">
        <div className="container mx-auto px-4">
          
          <h3 className="text-4xl font-bold font-apparat">Schedule a Call with Me</h3>
                <hr className=" border-b-8 my-8 w-24 border-accent border-t-0"/>

          <div className="md:grid md:grid-cols-2 gap-8">
          <div>
          <p className="text-xl mb-8">If you are ready to get started or you have some questions, I would be happy to set up a call with you. Just fill out the form below!</p>
            <form netlify="true" name="callback" onSubmit={submitForm} method="post" name="callback">
        <input type="hidden" name="form-name" value="callback" />
        <div className="space-y-4">
          <div class="">
            <label className="font-bold font-sans uppercase text-black">Name *</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="bg-light w-full block border-2 border-secondary rounded block px-3 py-4 text-xl" name="name"/>
          </div>
          <div class="">
            <label className="font-bold font-sans uppercase text-black">Business Name *</label>
            <input type="text" value={businessName} onChange={(e) => setBusinessName(e.target.value)} className="bg-light w-full block border-2 border-secondary rounded block px-3 py-4 text-xl" name="business_name"/>
          </div>
          <div>
            <label className="font-bold font-sans uppercase text-black">Phone *</label>
            <input type="text" ref={phoneRef} className="w-full block border-2 bg-light border-secondary rounded block px-3 py-4 text-xl" name="phone"/>
          </div>
          <div>
            <label className="font-bold font-sans uppercase text-black">Email *</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="w-full block bg-light border-2 border-secondary text-xl rounded block px-3 py-4" name="email"/>
          </div>
          
          <div>
          <input type="hidden" value={selected} name="date"/>

          <label className="font-bold font-sans uppercase text-black">Preferred Call Date</label>
          <div className="overflow-auto no-scroll max-w-full w-full">
            <div className="flex space-x-4">
            {
              dates.map(date => <button type="button" onClick={() => toggleDate(moment(date).format('M/D'))} key={`date-${moment(date).unix()}`} className={`shrink-0 w-[32vw] sm:w-36 divide-y px-3 py-3 aspect-square border-2 rounded  ${selected==(moment(date).format('M/D'))?'bg-accent text-black divide-black border-accent':' divide-black bg-light border-secondary'}`}>
                  <div className="divide-y divide-black">
                  <div className="py-1">
                    <span className="text-sm sm:text-base uppercase tracking-wider">{moment(date).format('dddd')}</span>
                  </div>
                   <div className="py-1">
                    <span className="text-lg font-bold">{moment(date).format('M/D')}</span>
                  </div>
                  </div>
                </button>)
            }
            </div>
          </div>
          </div>
          <div>
          <input type="hidden" value={selectedTime} name="time"/>
          <label className="font-bold font-sans uppercase text-black">Preferred Call Time</label>
          <div className="overflow-x-auto overflow-y-visible no-scroll ">
            <div className="flex space-x-4">
            {
              times.map(time => <button type="button" onClick={() => toggleTime(moment(time).hour())} key={`hour-${moment(time).hour()}`} className={`shrink-0 w-[32vw] sm:w-36  px-3 py-3 relative aspect-square border-2 rounded ${selectedTime==moment(time).hour()?'bg-accent border-accent text-black divide-white':' divide-black bg-light border-secondary'}`}>
                 
                  <div className={`divide-y ${selectedTime==moment(time).hour()?'divide-white':'divide-black'}`}>
                  
                   <div className="py-1">
                    <span className="text-2xl font-bold uppercase">{moment(time).format('h a')}</span>
                  </div>
                  </div>
                </button>)
            }
            </div>
          </div>
          </div>
          
          <div>
            <ReCAPTCHA
            sitekey="6LdtgBcqAAAAAAxEOBsgindcisdSvqJLllwsiVso"
            onChange={setCaptcha}
          />
          </div>
          <div>
            {error&&<p className="text-red-600 text-xl font-medium">{error}</p>}
            {showMessage&&<p className="text-green-600 font-medium text-xl">Thanks, {name}! I'll be in touch!</p>}
          </div>
          <div>
            <button disabled={disabled||captcha==''} className="disabled:opacity-50 btn-primary">Submit</button>
          </div>
        </div>
      </form>
      </div>
          </div>
        </div>
    </div>
    <div className="py-10">
      <div className="container mx-auto text-center">
        &copy; All Rights Reserved {new Date().getFullYear()}, Restaurant Sites NYC
      </div>
    </div>
  </main>
}

export const Head = () => <>
  <title>Dave Luke - Custom Designed Websites for Restaurants in NYC</title>
      <meta property="description" content="Custom designed websites for restaurants built by a NYC based web designer."/>

      <meta property="og:title" content="Custom Designed Websites for Restaurants in NYC"/>
      <meta property="og:description" content="Custom designed websites for restaurants by a NYC based web designer."/>
      <meta property="og:image" content="https://res.cloudinary.com/meshed-nyc/image/upload/v1721834258/burg-1200-630_eek6on.png"/>
      
</>

export default IndexPage
